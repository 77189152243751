import { BrowserRouter as Router, Route } from "react-router-dom";
import { DataProvider } from "./context/Providers";
import PrivateRoute from "./PrivateRoute";
import Login from "./Login";
import Dashboard from "./Dashboard";
import ProvidersDashboard from "./ProvidersDashboard";
import ProviderProfile from "./ProviderProfile";
import ProviderInvoices from "./ProviderInvoices";
import { PendingInvoicesProvider } from "./context/PendingInvoices";

function App() {
  return (
    <>
      <Router>
        <Route exact path="/login" component={Login} />
        <DataProvider>
          <PendingInvoicesProvider>
            <PrivateRoute exact path="/" component={Dashboard} />
          </PendingInvoicesProvider>
          <PrivateRoute exact path="/proveedores" component={ProvidersDashboard}/>
          <PrivateRoute exact path="/proveedor/perfil/:id" component={ProviderProfile} />
          <PrivateRoute exact path="/proveedor/facturas/:id" component={ProviderInvoices} />
        </DataProvider>
      </Router>
    </>
  );
}

export default App;
