import { useState, useContext, useEffect } from "react";
import { PendingInvoicesContext } from "./context/PendingInvoices";
import Nav from "./particles/Nav";
import Autocomplete from "./particles/Autocomplete";
import InvoiceList from "./particles/InvoiceList";
import { userFields } from "./helpers/data";
import { Grid, Box } from "@chakra-ui/react";

function Dashboard() {
  const { pendingInvoices } = useContext(PendingInvoicesContext);
  const [field, setField] = useState(null);
  const [value, setValue] = useState(null);
  const [invoices, setInvoices] = useState([]);

  useEffect(() => {
    if (value && field) {
      let copy = [...pendingInvoices];
      let res = copy.filter((e) => e[field] === value);
      setInvoices(res);
    }
    if (!value && !field) {
      let copy = [...pendingInvoices];
      setInvoices(copy);
    }
  }, [value, field, pendingInvoices]);

  return (
    <>
      <Nav title={"Facturas Pendientes"} />
      <Box bg="gray.100" minHeight="100vh" py={20} px={2}>
        <Grid justifyContent="center" templateColumns="minmax(auto,1400px)">
          <Autocomplete
            fields={userFields}
            data={invoices}
            setField={setField}
            setValue={setValue}
          />
          <Box
            background="white"
            p={{ base: 3, md: 6 }}
            boxShadow="sm"
            overflowX="auto"
            overflowY="auto"
            rounded={4}
          >
            <InvoiceList data={invoices} messages={false} />
          </Box>
        </Grid>
      </Box>
    </>
  );
}

export default Dashboard;
