export const userFields = [
  {
    field: "default",
    name: "Filtros",
  },
  {
    field: "rfc",
    name: "RFC",
  },
  {
    field: "folio",
    name: "Folio",
  },
  {
    field: "proyect",
    name: "Proyecto",
  },
  {
    field: "tradename",
    name: "Nombre Comercial",
  },
  {
    field: "name",
    name: "Razon Social",
  },
  {
    field: "date",
    name: "Fecha",
  },
];

export const invoiceLabels = {
  0: "Faltante",
  1: "Pendiente",
  2: "Rechazada",
  3: "Aprobada",
};

export const providersFields = [
  {
    field: "default",
    name: "Filtros",
  },
  {
    field: "rfc",
    name: "RFC",
  },
  {
    field: "tradename",
    name: "Nombre Comercial",
  },
  {
    field: "name",
    name: "Razon Social",
  },
];

export const documentLabels = {
  0: "Faltante",
  1: "Pendiente",
  2: "Rechazado",
  3: "Aprobado",
};

export const documentData = [
  {
    key: 1,
    name: "file1",
    file: "acta_constitutiva.pdf",
    title: "Acta Constitutiva",
  },
  {
    key: 2,
    name: "file2",
    file: "constancia_fiscal.pdf",
    title: "Constancia de situación fiscal",
  },
  {
    key: 3,
    name: "file3",
    file: "opinion_de_cumplimiento.pdf",
    title: "Opinión de cumplimiento",
  },
  {
    key: 4,
    name: "file4",
    file: "identificacion.pdf",
    title: "Identificación oficial vigente del representante legal",
  },
  {
    key: 5,
    name: "file5",
    file: "comprobante_de_domicilio.pdf",
    title: "Comprobante de domicilio",
  },
  {
    key: 6,
    name: "file6",
    file: "estado_de_cuenta.pdf",
    title: "Carátula de estado de cuenta",
  },
];

export const providerFields = [
  {
    field: "default",
    name: "Filtros",
  },
  {
    field: "folio",
    name: "Folio",
  },
  {
    field: "proyect",
    name: "Proyecto",
  },
  {
    field: "date",
    name: "Fecha",
  },
];

export const tableFields = {
  date: "Fecha",
  rfc: "RFC",
  folio: "Folio",
  proyect: "Proyecto",
  tradename: "Nombre Comercial",
  name: "Razón Social",
  status: "Estatus",
  preview: "Prevista",
  download: "Descarga",
  downloads: "Descargas",
  files: "Archivos",
  actions: "Acciones",
  action: "Acción",
  message: "Motivo",
  documentsPending: "Docs. a Revisar",
  documentsApproved: "Docs. Aprobados",
  links: "Ver",
  document: "Documento",
};
