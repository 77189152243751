import { storage } from "../util/firebase";
import { getDownloadURL, ref } from "firebase/storage";
import { Button } from "@chakra-ui/react";
import { ViewIcon } from "@chakra-ui/icons";

function PreviewFile({ path, disabled }) {
  async function handleClick(e) {
    e.preventDefault();
    const url = await getDownloadURL(ref(storage, path));
    window.open(url);
  }

  return (
    <>
      <Button
        onClick={handleClick}
        disabled={disabled}
        size="xs"
        colorScheme="blue"
        rounded={2}
        mr={1}
        leftIcon={<ViewIcon />}
      >
        Prevista
      </Button>
    </>
  );
}

export default PreviewFile;
