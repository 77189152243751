import UploadProvider from "./UploadProvider";
import DocumentsPercent from "./DocumentsPercent";
import DocumentsCounter from "./DocumentsCounter";
import ButtonLink from "./ButtonLink";
import { tableFields } from "../helpers/data";
import { Table, Thead, Tbody, Tr, Th, Td, Tag, Flex } from "@chakra-ui/react";
import { Icon, CopyIcon } from "@chakra-ui/icons";
import { FaRegUser } from "react-icons/fa";

function ProvidersList({ data }) {
  return (
    <>
      <Table size="md" fontSize="sm">
        <Thead>
          <Tr>
            <Th>{tableFields.rfc}</Th>
            <Th>{tableFields.name}</Th>
            <Th>{tableFields.tradename}</Th>
            <Th>{tableFields.documentsPending}</Th>
            <Th>{tableFields.documentsApproved}</Th>
            <Th>{tableFields.status}</Th>
            <Th>{tableFields.links}</Th>
            <Th>{tableFields.action}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((item) => (
            <Tr key={item.uid}>
              <Td>{item.rfc}</Td>
              <Td>{item.name}</Td>
              <Td>{item.tradename}</Td>
              <Td>
                <DocumentsCounter
                  a={item.file1}
                  b={item.file2}
                  c={item.file3}
                  d={item.file4}
                  e={item.file5}
                  f={item.file6}
                  value={1}
                />
              </Td>
              <Td>
                <DocumentsPercent
                  a={item.file1}
                  b={item.file2}
                  c={item.file3}
                  d={item.file4}
                  e={item.file5}
                  f={item.file6}
                  value={3}
                />
              </Td>
              <Td>
                <Tag
                  size="sm"
                  rounded={10}
                  colorScheme={item.userState ? "green" : "red"}
                >
                  {item.userState ? "Activo" : "Desactivado"}
                </Tag>
              </Td>
              <Td>
                <Flex>
                  <ButtonLink
                    path={`/proveedor/perfil/${item.uid}`}
                    label="Perfil"
                    icon={<Icon as={FaRegUser} />}
                  />
                  <ButtonLink
                    path={`/proveedor/facturas/${item.uid}`}
                    label="Facturas"
                    icon={<CopyIcon />}
                  />
                </Flex>
              </Td>
              <Td>
                <UploadProvider id={item.uid} state={item.userState} />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </>
  );
}

export default ProvidersList;
