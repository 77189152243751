import { useContext, useEffect, useState } from "react";

import { auth, db } from "./util/firebase";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { getDoc, doc } from "firebase/firestore";

import { AuthContext } from "./context/Auth";

import {
  Flex,
  Heading,
  FormLabel,
  Input,
  Button,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Image,
  Link,
} from "@chakra-ui/react";

function Login({ history }) {
  const { currentUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setAlertMessage(null);
    setAlert(false);
    const { email, password } = event.target.elements;
    try {
      await signInWithEmailAndPassword(auth, email.value, password.value);
    } catch (error) {
      signOut(auth);
      setLoading(false);
      setAlertMessage("Usuario y/o contraseña incorrectos");
      setAlert(true);
      console.log(error);
    }
  };

  useEffect(() => {
    const verifyState = async (uid) => {
      const docRef = doc(db, "admin", uid);
      const snap = await getDoc(docRef);
      if (snap.exists()) {
        let state = snap.data().state;
        if (state) {
          history.push("./");
          return;
        }
        signOut(auth);
        setAlertMessage("Usario no autorizado");
        setLoading(false);
        setAlert(true);
      }
      signOut(auth);
      setAlertMessage("Usario no autorizado");
      setLoading(false);
      setAlert(true);
    };

    if (currentUser) {
      verifyState(currentUser);
    }
  }, [currentUser, history]);

  return (
    <>
      <Flex
        height="100vh"
        alignItems="center"
        justifyContent="center"
        background="gray.100"
        py={6}
        px={2}
        flexDirection="column"
      >
        <Box w="180px" mb={6}>
          <Image src="bengala.svg" alt="Casa Bengala" w="100%" />
        </Box>
        <Flex
          direction="column"
          background="white"
          p={10}
          rounded={4}
          boxShadow="lg"
          w="380px"
        >
          <Heading mb={4} fontSize="2xl">
            Administrador
          </Heading>
          <form onSubmit={handleSubmit}>
            <Flex direction="column">
              <FormLabel fontSize="sm">Email:</FormLabel>
              <Input
                placeholder="Ingresa tu correo"
                type="email"
                name="email"
                size="md"
                mb={3}
                rounded={4}
                maxLength={70}
              />
              <FormLabel fontSize="sm">Password:</FormLabel>
              <Input
                type="password"
                placeholder="********"
                name="password"
                size="md"
                mb={3}
                rounded={4}
                maxLength={16}
              />
              <Button
                colorScheme="blue"
                type="submit"
                loadingText="Verificando"
                isLoading={loading}
                rounded={4}
              >
                Entrar
              </Button>
              {alert ? (
                <Alert status="error" mt={3}>
                  <AlertIcon />
                  <AlertTitle mr={2}>Error:</AlertTitle>
                  <AlertDescription>{alertMessage}</AlertDescription>
                </Alert>
              ) : null}
            </Flex>
          </form>
        </Flex>
        <Link
          href="https://casabengala.com/"
          isExternal
          mt={6}
          fontSize="sm"
          color="gray.500"
        >
          www.casabengala.mx
        </Link>
      </Flex>
    </>
  );
}

export default Login;
