import { db } from "../util/firebase";
import { updateDoc, doc } from "firebase/firestore";
import { Button } from "@chakra-ui/react";
import { Icon } from "@chakra-ui/icons";
import { FaPowerOff, FaRedo } from "react-icons/fa";

function UploadProvider({ id, state }) {
  const docRef = doc(db, "providers", id);

  async function updateProvider(newState) {
    try {
      await updateDoc(docRef, {
        userState: newState,
      });
    } catch (error) {
      console.log(error.message);
    }
  }

  function handleClick() {
    if (state) {
      updateProvider(false);
      return;
    }
    updateProvider(true);
  }

  return (
    <>
      <Button
        rounded={2}
        onClick={handleClick}
        size="xs"
        colorScheme={state ? "red" : "blue"}
        leftIcon={state ? <Icon as={FaPowerOff} /> : <Icon as={FaRedo} />}
      >
        {state ? "Desactivar" : "Activar"}
      </Button>
    </>
  );
}

export default UploadProvider;
