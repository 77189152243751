import ApproveFile from "./ApproveFile";
import DownloadFile from "./DownloadFile";
import Label from "./Label";
import PreviewFile from "./PreviewFile";
import RejectFile from "./RejectFile";

import { documentData, documentLabels, tableFields } from "../helpers/data";

import { Table, Thead, Tbody, Tr, Th, Td, Flex } from "@chakra-ui/react";

function ProviderTable({ id, provider }) {
  return (
    <>
      <Table fontSize="sm" size="sm">
        <Thead>
          <Tr>
            <Th>{tableFields.document}</Th>
            <Th>{tableFields.status}</Th>
            <Th>{tableFields.download}</Th>
            <Th colSpan={2}>{tableFields.actions}</Th>
            <Th>{tableFields.message}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {documentData.map((file, i) => (
            <Tr key={i}>
              <Td>{documentData[i].title}</Td>
              <Td>
                <Label
                  labels={documentLabels}
                  state={provider[documentData[i].name]}
                />
              </Td>
              <Td>
                <Flex>
                  <PreviewFile
                    path={`docs/${id}/${documentData[i].file}`}
                    disabled={
                      provider[documentData[i].name] === 0 ? true : false
                    }
                  />
                  <DownloadFile
                    path={`docs/${id}/`}
                    file={documentData[i].file}
                    label="PDF"
                    disabled={
                      provider[documentData[i].name] === 0 ? true : false
                    }
                  />
                </Flex>
              </Td>
              <Td>
                <ApproveFile
                  id={id}
                  file={documentData[i].name}
                  state={provider[documentData[i].name]}
                  index={i}
                />
              </Td>
              <Td>
                <RejectFile
                  id={id}
                  file={documentData[i].name}
                  state={provider[documentData[i].name]}
                  index={i}
                  data={provider}
                />
              </Td>
              <Td>
                {provider[documentData[i].name] === 2
                  ? provider[`messageFile${i + 1}`]
                  : null}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </>
  );
}

export default ProviderTable;
