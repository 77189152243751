import { storage } from "../util/firebase";
import { getDownloadURL, ref } from "firebase/storage";

import { Button } from "@chakra-ui/react";
import { DownloadIcon } from "@chakra-ui/icons";

function DownloadFile({ label, path, file, disabled }) {
  async function handleClick(e) {
    e.preventDefault();
    try {
      const url = await getDownloadURL(ref(storage, `${path}/${file}`));
      const xhr = new XMLHttpRequest();
      xhr.responseType = "blob";
      xhr.onload = function () {
        const blob = xhr.response;
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = file;
        link.click();
        URL.revokeObjectURL(link.href);
      };
      xhr.open("GET", url);
      xhr.send();
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <Button
        size="xs"
        variant="outline"
        rounded={2}
        colorScheme="blue"
        onClick={handleClick}
        disabled={disabled}
        leftIcon={<DownloadIcon />}
        mr={1}
      >
        {label}
      </Button>
    </>
  );
}

export default DownloadFile;
