import { Link } from "react-router-dom";

import Label from "./Label";
import PreviewFile from "./PreviewFile";
import DownloadFile from "./DownloadFile";
import ApproveInvoice from "./ApproveInvoice";
import RejectInvoice from "./RejectInvoice";

import { invoiceLabels, tableFields } from "../helpers/data";
import { Table, Thead, Tbody, Tr, Th, Td, Flex, Text } from "@chakra-ui/react";

function InvoiceList({ data, messages, shortTable }) {
  return (
    <>
      <Table size="sm" fontSize="sm">
        <Thead>
          <Tr>
            <Th>{tableFields.date}</Th>
            <Th>{tableFields.rfc}</Th>
            <Th>{tableFields.folio}</Th>
            <Th>{tableFields.proyect}</Th>
            {!shortTable ? <Th>{tableFields.tradename}</Th> : null}
            <Th>{tableFields.status}</Th>
            <Th>{tableFields.files}</Th>
            <Th colSpan={2}>{tableFields.actions}</Th>
            {messages ? <Th>{tableFields.message}</Th> : null}
          </Tr>
        </Thead>
        <Tbody>
          {data.map((item) => (
            <Tr key={item.id}>
              <Td>{item.date}</Td>
              <Td>
                <Link to={`/proveedor/perfil/${item.uid}`}>
                  <Text color="blue"> {item.rfc}</Text>{" "}
                </Link>
              </Td>
              <Td>{item.folio}</Td>
              <Td>{item.proyect}</Td>
              {!shortTable ? <Td>{item.tradename}</Td> : null}
              <Td>
                <Label state={item.state} labels={invoiceLabels} />
              </Td>
              <Td>
                <Flex>
                  <PreviewFile path={`invoices/${item.path}/${item.pdf}`} />
                  <DownloadFile
                    label="PDF"
                    path={`invoices/${item.path}/`}
                    file={item.pdf}
                  />
                  <DownloadFile
                    label="XML"
                    path={`invoices/${item.path}/`}
                    file={item.xml}
                  />
                </Flex>
              </Td>
              <Td>
                <ApproveInvoice id={item.id} state={item.state} />
              </Td>
              <Td>
                <RejectInvoice id={item.id} state={item.state} />
              </Td>
              {messages ? <Td>{item.message}</Td> : null}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </>
  );
}

export default InvoiceList;
