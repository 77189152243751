import { useRef } from "react";
import { auth, db } from "../util/firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { setDoc, doc } from "firebase/firestore";
import { useState } from "react";


import {
  Flex,
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Textarea,
  Grid,
  GridItem,
  Heading,
  Box,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/react";
import { Icon } from "@chakra-ui/icons";
import { FaUserPlus } from "react-icons/fa";

const alertInitialState = {
  state: false,
  type: null,
  title: null,
  message: null,
};

function AddProvider({ close }) {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(alertInitialState);
  const formRef = useRef(null);
  
  const handleClickPass = () => setShow(!show);
  
  async function createUser(email, password) {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      return userCredential;
    } catch (e) {
      setLoading(false);
      let message;
      if (e.code === "auth/email-already-in-use") {
        message = "El email ya se encuentra en uso";
      } else if (e.code === "auth/weak-password") {
        message = "El password es demasiado débil";
      } else {
        message = e.code;
      }
      setAlert({
        state: true,
        type: "error",
        title: "Error:",
        message,
      });
    }
  }

  async function saveData(uid, data) {
    try {
      await setDoc(doc(db, "providers", uid), data);
      return true;
    } catch (e) {
      setLoading(false);
      setAlert({
        state: true,
        type: "error",
        title: "Error:",
        message: e.code,
      });
      return false;
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    setAlert(alertInitialState);
    const {
      name,
      rfc,
      tradename,
      address,
      phone,
      user,
      userPhone,
      userEmail,
      userPassword,
    } = e.target.elements;

    /* 
        file1: Acta constitutiva Faltante
        file2: Constancia de situación fiscal
        file3: Opinión de cumplimiento
        file4: Identificación oficial vigente del representante legal
        file5: Comprobante de domicilio
        file6: Carátula de estado de cuenta 
    */

    const time = new Date().getTime();

    const data = {
      name: name.value,
      rfc: rfc.value,
      tradename: tradename.value,
      address: address.value,
      phone: phone.value,
      user: user.value,
      userPhone: userPhone.value,
      userEmail: userEmail.value,
      userPassword: userPassword.value,
      userState: true,
      file1: 0,
      file2: 0,
      file3: 0,
      file4: 0,
      file5: 0,
      file6: 0,
      messageFile1: null,
      messageFile2: null,
      messageFile3: null,
      messageFile4: null,
      messageFile5: null,
      messageFile6: null,
      timestamp: time,
    };

    if(userPassword.value.length < 8) {
      setLoading(false);
      setAlert({
        state: true,
        type: "error",
        title: "Error:",
        message: "El password requiere 8 caracteres",
      });
      return;
    }

    /*
      0: Sin enviar
      1: En Revision
      2: Rechazada
      3: Aprobada
    */

    try {
      const userCredential = await createUser(
        data.userEmail,
        data.userPassword
      );
      if (!userCredential) return;
      const save = await saveData(userCredential.user.uid, data);
      if (!save) return;
      setTimeout(() => {
        formRef.current.reset();
        setLoading(false);
        setAlert({
          state: true,
          type: "success",
          title: "Éxito:",
          message: "Proveedor registrado correctamente",
        });
      }, 1000);
    } catch (error) {
      setLoading(false);
      setAlert({
        state: true,
        type: "error",
        title: "Error:",
        message: error,
      });
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit} style={{ width: "100%" }} ref={formRef}>
        <Grid justifyContent="center" templateColumns="minmax(auto,960px)">
          <Heading fontSize="2xl" pt={12} mb={6}>
            Agregar Proveedor
          </Heading>
          <Grid
            justifyContent="center"
            templateColumns={{ base: "1fr", md: "repeat(2,minmax(auto,1fr))" }}
            gap={12}
          >
            <GridItem>
              <FormLabel fontSize="xs">Razon Social:</FormLabel>
              <Input rounded={4} size="md" type="text" name="name" mb={3} />
              <FormLabel fontSize="xs">RFC:</FormLabel>
              <Input rounded={4} size="md" type="text" name="rfc" mb={3} />
              <FormControl isRequired mb={3}>
                <FormLabel fontSize="xs">Nombre Comercial:</FormLabel>
                <Input rounded={4} size="md" type="text" name="tradename" />
              </FormControl>
              <FormLabel fontSize="xs">Telefono:</FormLabel>
              <Input rounded={4} mb={3} size="md" type="text" name="phone" />
              <FormLabel fontSize="xs">Direccion:</FormLabel>
              <Textarea
                rounded={4}
                mb={3}
                rows={2}
                size="md"
                type="text"
                name="address"
              />
            </GridItem>
            <GridItem>
              <FormLabel fontSize="xs">Nombre del representante:</FormLabel>
              <Input rounded={4} mb={3} size="md" type="text" name="user" />
              <FormLabel fontSize="xs">Teléfono del representante:</FormLabel>
              <Input
                rounded={4}
                mb={3}
                size="md"
                type="text"
                name="userPhone"
              />
              <Box background="gray.100" px={10} pt={10} pb={16}>
                <FormControl isRequired mb={3}>
                  <FormLabel fontSize="xs">Email</FormLabel>
                  <Input
                    background="white"
                    borderRadius={4}
                    size="md"
                    type="email"
                    name="userEmail"
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel fontSize="xs">Password</FormLabel>
                  <InputGroup size="md">
                    <Input
                      pr="4.5rem"
                      type={show ? "text" : "password"}
                      name="userPassword"
                      background="white"
                    />
                    <InputRightElement width="4.5rem">
                      <Button
                        rounded={2}
                        h="1.60rem"
                        size="xs"
                        onClick={handleClickPass}
                      >
                        {show ? "Ocultar" : "Mostrar"}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
              </Box>
              {alert.state ? (
                <Alert status={alert.type} mt={3}>
                  <AlertIcon />
                  <AlertTitle>{alert.title}</AlertTitle>
                  <AlertDescription>{alert.message}</AlertDescription>
                </Alert>
              ) : null}
              <Flex mt={3} justifyContent="end">
                <Button
                  size="sm"
                  onClick={close}
                  colorScheme="blue"
                  mr={12}
                  variant="link"
                >
                  Cancelar
                </Button>
                <Button
                  rounded={4}
                  size="sm"
                  type="submit"
                  colorScheme="blue"
                  p={2}
                  leftIcon={<Icon as={FaUserPlus} />}
                  loadingText="Agregando Proveedor"
                  isLoading={loading}
                >
                  Agregar Proveedor
                </Button>
              </Flex>
            </GridItem>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

export default AddProvider;
