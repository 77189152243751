import { useEffect, useState, createContext, useContext } from "react";
import { ProvidersContext } from "../context/Providers";
import { db } from "../util/firebase";
import {
  collection,
  query,
  onSnapshot,
  orderBy,
  where,
} from "firebase/firestore";
import Loading from "../particles/Loading";

export const PendingInvoicesContext = createContext();

export const PendingInvoicesProvider = ({ children }) => {
  const { providers } = useContext(ProvidersContext);
  const [pending, setPending] = useState(true);
  const [pendingInvoices, setPendingInvoices] = useState(true);

  useEffect(() => {
    const queryInvoices = query(
      collection(db, "invoices"),
      where("state", "==", 1),
      orderBy("time", "desc")
    );
    const unsubscribeInvoices = onSnapshot(queryInvoices, (snap) => {
      let docsInvoices = [];
      snap.forEach((doc) => {
        let data = doc.data();
        let unixTime = doc.data().time;
        let date = new Date(unixTime);
        docsInvoices.push({
          ...data,
          id: doc.id,
          date: date.toLocaleDateString("es-MX"),
        });
      });
      let data = [];
      docsInvoices.forEach((invoice) => {
        providers.forEach((provider) => {
          if (invoice.provider === provider.uid) {
            data.push({ ...invoice, ...provider });
          }
        });
      });
      setPendingInvoices(data);
      setPending(false);
    });
    return () => {
      unsubscribeInvoices();
    };
  }, [providers]);

  if (pending) {
    return (
      <>
        <Loading />
      </>
    );
  }

  return (
    <PendingInvoicesContext.Provider value={{ pendingInvoices }}>
      {children}
    </PendingInvoicesContext.Provider>
  );
};
