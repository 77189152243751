import { useState, useEffect } from "react";
import { db } from "../util/firebase";
import { doc, updateDoc } from "firebase/firestore";
import { Button } from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";

function ApproveFile({ id, state, file, index }) {
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  async function handleClick() {
    setLoading(true)
    const docRef = doc(db, "providers", id);
    try {
      await updateDoc(docRef, { [file]: 3, [`messageFile${index + 1}`]: null });
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  useEffect(() => {
    if (state === 3 || state === 0) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [state]);

  return (
    <>
      <Button
        rounded={2}
        size="xs"
        colorScheme="blue"
        disabled={disabled}
        onClick={handleClick}
        leftIcon={<CheckIcon />}
        loadingText="Actualizando"
        isLoading={loading}
      >
        Aprobar
      </Button>
    </>
  );
}

export default ApproveFile;
