import { Tag } from "@chakra-ui/react";

function Label({ state, labels }) {
  if (state === 1) {
    return (
      <Tag rounded={10} colorScheme="yellow" size="sm">
        {labels[1]}
      </Tag>
    );
  }

  if (state === 2) {
    return (
      <Tag rounded={10} colorScheme="red" size="sm">
        {labels[2]}
      </Tag>
    );
  }

  if (state === 3) {
    return (
      <Tag rounded={10} colorScheme="green" size="sm">
        {labels[3]}
      </Tag>
    );
  }

  return (
    <Tag rounded={10} colorScheme="red" size="sm">
      {labels[0]}
    </Tag>
  );
}

export default Label;
