import { useHistory } from "react-router-dom";
import { Button } from "@chakra-ui/react";

function ProviderLinks({ path, label, icon }) {
  let history = useHistory();

  function handleClick() {
    history.push(path);
  }

  return (
    <>
      <Button
        colorScheme="blue"
        size="xs"
        variant="outline"
        rounded={2}
        ml={1}
        onClick={handleClick}
        leftIcon={icon}
      >
        {label}
      </Button>
    </>
  );
}

export default ProviderLinks;
