import { useState, useEffect, useRef } from "react";
import { db } from "../util/firebase";
import { doc, updateDoc } from "firebase/firestore";
import { Button, Textarea, Stack } from "@chakra-ui/react";
import { Icon } from "@chakra-ui/icons";
import { FaUndo } from "react-icons/fa";

function RejectInvoice({ id, state }) {
  const [disabled, setDisabled] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);
  const [loading, setLoading] = useState(false);
  const inputRef = useRef(null);

  function handleChange() {
    let value = inputRef.current.value;
    if (value.length > 10 && disabledButton) {
      setDisabledButton(false);
    }
    if (value.length < 10 && !disabledButton) {
      setDisabledButton(true);
    }
  }

  async function handleClick() {
    setLoading(true);
    let value = inputRef.current.value;
    const docRef = doc(db, "invoices", id);
    try {
      await updateDoc(docRef, { state: 2, message: value });
      inputRef.current.value = "";
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  useEffect(() => {
    if (state === 2) {
      setDisabled(true);
      setDisabledButton(true);
    } else {
      setDisabled(false);
    }
  }, [state]);

  return (
    <>
      <Stack minWidth="150px" w="100%">
        <Textarea
          size="xs"
          rounded={2}
          onChange={handleChange}
          type="text"
          disabled={disabled}
          ref={inputRef}
          maxLength={144}
          rows={2}
        />
        <Button
          disabled={disabledButton}
          onClick={handleClick}
          size="xs"
          colorScheme="red"
          mt={2}
          rounded={2}
          leftIcon={<Icon as={FaUndo} />}
          loadingText="Actualizando"
          isLoading={loading}
        >
          Rechazar
        </Button>
      </Stack>
    </>
  );
}

export default RejectInvoice;
