import { useEffect, useState, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { ProvidersContext } from "./context/Providers";
import Nav from "./particles/Nav";
import EditProvider from "./particles/EditProvider";

import { Box, Flex, Grid, Button } from "@chakra-ui/react";
import ProviderTable from "./particles/ProviderTable";
import { Icon } from "@chakra-ui/icons";
import { FaCopy } from "react-icons/fa";

function ProviderProfile() {
  const { providers } = useContext(ProvidersContext);

  let { id } = useParams();
  let history = useHistory();

  const [provider, setProvider] = useState([]);

  function handleClick(e, path) {
    e.preventDefault();
    history.push(path);
  }

  useEffect(() => {
    const copy = [...providers];
    const filterData = copy.filter((e) => e.uid === id);
    setProvider(...filterData);
  }, [providers, id]);

  return (
    <>
      <Nav title={`${provider.tradename}`} state={provider.userState} />
      <Box bg="gray.100" minH="100vh" py={20} px={2}>
        <Grid justifyContent="center" templateColumns="minmax(auto,1400px)">
          <Flex mb={6} justifyContent="end">
            <Button
              onClick={(e) => handleClick(e, `/proveedor/facturas/${id}`)}
              colorScheme="blue"
              rounded={4}
              size="sm"
              leftIcon={<Icon as={FaCopy} />}
            >
              Ver Facturas
            </Button>
          </Flex>
          <Flex mb={3}>
            <EditProvider />
          </Flex>
          <Flex
            background="white"
            p={{ base: 3, md: 6 }}
            boxShadow="sm"
            overflowX="auto"
            overflowY="auto"
            rounded={4}
          >
            <ProviderTable id={id} provider={provider} />
          </Flex>
        </Grid>
      </Box>
    </>
  );
}

export default ProviderProfile;
