import { useEffect, useState, createContext } from "react";

import { db } from "../util/firebase";
import { collection, query, onSnapshot, orderBy } from "firebase/firestore";
import Loading from "../particles/Loading";

export const ProvidersContext = createContext();

export const DataProvider = ({ children }) => {
  const [providers, setProviders] = useState([]);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    const q = query(collection(db, "providers"), orderBy("timestamp"));
    const unsubscribe = onSnapshot(q, (snap) => {
      const docs = [];
      snap.forEach((doc) => {
        let data = doc.data();
        docs.push({
          ...data,
          uid: doc.id,
        });
      });
      setProviders(docs);
      setPending(false);
    });
    return () => unsubscribe();
  }, []);

  if (pending) {
    return (
      <>
        <Loading/>
      </>
      );
  }

  return (
    <ProvidersContext.Provider value={{ providers }}>
      {children}
    </ProvidersContext.Provider>
  );
};
