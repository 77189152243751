import { useState, useRef } from "react";

import { Flex, Input, Button, Select, Box, Stack } from "@chakra-ui/react";

import { RepeatIcon } from "@chakra-ui/icons";

function Autocomplete({ setField, setValue, data, fields }) {
  const [options, setOptions] = useState([]);
  const [coincidence, setCoincidence] = useState([]);
  const [visible, setVisible] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const inputRef = useRef();
  const selectRef = useRef();

  function handleReset() {
    setField(null);
    setValue(null);
    setCoincidence([]);
    setDisabled(true);
    setVisible(false);
    const select = selectRef.current;
    select.firstChild.selected = true;
    inputRef.current.value = "";
  }

  function handleSelect(e) {
    setValue(null);
    inputRef.current.value = "";
    let field = e.target.value;
    if (field !== "default") {
      const fieldOptions = [];
      data.forEach((e) => fieldOptions.push(e[field]));
      const cleanDuplicate = [...new Set(fieldOptions)];
      setOptions(cleanDuplicate);
      setField(field);
      setDisabled(false);
      return;
    }
    setDisabled(true);
  }

  function handleChangue(e) {
    let value = e.target.value;
    if (value.length >= 1) {
      const unLinked = options.filter(
        (opt) => opt?.toLowerCase().indexOf(value.toLowerCase()) > -1
      );
      setCoincidence(unLinked);
      setVisible(true);
      return;
    }
    setValue(null);
    setCoincidence([]);
    setVisible(false);
  }

  function handleClick(e) {
    let selected = e.target.id;
    inputRef.current.value = selected;
    setValue(selected);
    setVisible(false);
  }

  return (
    <>
      <Flex
        mb={{ base: 3, sm: 6 }}
        flexDirection={{ base: "column-reverse", sm: "row" }}
      >
        <Flex position="relative">
          <Select
            size="sm"
            variant="filled"
            borderRadius={4}
            background="white"
            mr={2}
            w={{ base: "40%", sm: 40 }}
            onChange={handleSelect}
            ref={selectRef}
            display="block"
          >
            {fields.map((item, i) => (
              <option key={i} value={item.field}>
                {item.name}
              </option>
            ))}
          </Select>
          <Input
            w={{ base: "60%", sm: 40 }}
            size="sm"
            borderRadius={4}
            background="white"
            onChange={handleChangue}
            ref={inputRef}
            disabled={disabled}
            mr={{base: 0, sm: 2}}
          />
          {visible && coincidence.length > 0 ? (
            <Box
              position="absolute"
              top={10}
              left={0}
              background="white"
              w="100%"
              boxShadow="lg"
              zIndex={2}
              rounded={4}
              p={2}
            >
              <Stack>
                {coincidence.map((item, i) => (
                  <Button
                    size="sm"
                    key={i}
                    onClick={handleClick}
                    id={item}
                    rounded={4}
                  >
                    {item}
                  </Button>
                ))}
              </Stack>
            </Box>
          ) : null}
        </Flex>
        <Button
          rounded={4}
          colorScheme="blue"
          size="sm"
          onClick={handleReset}
          leftIcon={<RepeatIcon />}
          mb={{ base: 3, sm: 0 }}
        >
          Limpiar
        </Button>
      </Flex>
    </>
  );
}

export default Autocomplete;
