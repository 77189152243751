import { useEffect, useState, useContext } from "react";
import { ProvidersContext } from "./context/Providers";
import Nav from "./particles/Nav";
import ProvidersList from "./particles/ProvidersList";
import Autocomplete from "./particles/Autocomplete";
import AddProvider from "./particles/AddProvider";
import { providersFields } from "./helpers/data";

import {
  Flex,
  Grid,
  Box,
  Button,
  ButtonGroup,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  useDisclosure,
  ModalCloseButton,
} from "@chakra-ui/react";

function ProvidersDashboard() {
  const { providers } = useContext(ProvidersContext);
  const [data, setData] = useState([]);
  const [backup, setBackup] = useState([]);
  const [togglestate, setToggleState] = useState(true);
  const [field, setField] = useState(null);
  const [value, setValue] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  function switchState() {
    setToggleState(!togglestate);
  }

  useEffect(() => {
    if (!field && !value) {
      let copy = [...providers];
      let res = copy.filter((e) => e.userState === togglestate);
      setData(res);
      setDisabled(false);
    }

    if (field && value) {
      let copy = [...providers];
      let res = copy.filter((e) => e[field] === value);
      setData(res);
      setDisabled(true);
    }
  }, [backup, togglestate, field, value, providers]);

  useEffect(() => {
    setBackup(providers);
  }, [providers]);

  return (
    <>
      {!disabled ? (
        <Nav
          title={
            togglestate ? "Proveedores Activos" : " Proveedores Desactivados"
          }
        />
      ) : (
        <Nav title={"Proveedores Todos"} />
      )}
      <Box bg="gray.100" minH="100vh" py={20} px={2}>
        <Grid justifyContent="center" templateColumns="minmax(auto,1400px)">
          <Flex
            justifyContent="space-between"
            flexDirection={{ base: "column-reverse", md: "row" }}
          >
            <Autocomplete
              fields={providersFields}
              data={providers}
              setField={setField}
              setValue={setValue}
            />
            <ButtonGroup
              size="sm"
              isAttached
              variant="outline"
              colorScheme="blue"
              mb={{ base: 3, md: 0 }}
            >
              <Button w={{ base: "100%", sm: "auto" }} variant="solid" onClick={switchState} disabled={disabled}>
                {togglestate ? "Ver Desactivados" : "Ver Activos"}
              </Button>
              <Button  w={{ base: "100%", sm: "auto" }} onClick={onOpen}>Agregar Proveedor</Button>
            </ButtonGroup>
          </Flex>
          <Flex
            background="white"
            p={{ base: 3, md: 6 }}
            boxShadow="sm"
            overflowX="auto"
            overflowY="auto"
            rounded={4}
          >
            <ProvidersList data={data} />
          </Flex>
        </Grid>
      </Box>
      <Modal  closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <AddProvider close={onClose}/>
          </ModalBody>
          <ModalFooter>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ProvidersDashboard;
