import { useEffect, useState, useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import { db } from "../util/firebase";
import { doc, updateDoc } from "firebase/firestore";
import { ProvidersContext } from "../context/Providers";

import {
  Flex,
  Grid,
  Button,
  Input,
  GridItem,
  FormLabel,
  Textarea,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  FormControl,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { RepeatIcon } from "@chakra-ui/icons";

const alertInitialState = {
  state: false,
  type: null,
  title: null,
  message: null,
};

function EditProvider() {
  const { providers } = useContext(ProvidersContext);
  let { id } = useParams();
  const [provider, setProvider] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(alertInitialState);
  const [show, setShow] = useState(false);
  const formRef = useRef(null);

  const showPassword = () => setShow(!show);

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    setAlert(alertInitialState);
    const { name, rfc, tradename, address, phone, user, userPhone } =
      e.target.elements;
    const data = {
      name: name.value,
      rfc: rfc.value,
      tradename: tradename.value,
      address: address.value,
      phone: phone.value,
      user: user.value,
      userPhone: userPhone.value,
    };
    try {
      const docRef = doc(db, "providers", id);
      await updateDoc(docRef, data);
      setTimeout(() => {
        setLoading(false);
        setAlert({
          state: true,
          type: "success",
          title: "Éxito:",
          message: "Proveedor actualizado correctamente",
        });
      }, 1000);
    } catch (error) {
      setLoading(false);
      setAlert({
        state: true,
        type: "error",
        title: "Error:",
        message: error.code,
      });
    }
  }

  function cancelEdit() {
    const { name, rfc, tradename, address, phone, user, userPhone } =
      formRef.current.elements;
    name.value = provider.name;
    rfc.value = provider.rfc;
    tradename.value = provider.tradename;
    address.value = provider.address;
    phone.value = provider.phone;
    user.value = provider.user;
    userPhone.value = provider.userPhone;
  }

  useEffect(() => {
    const copy = [...providers];
    const filterData = copy.filter((e) => e.uid === id);
    setProvider(...filterData);
  }, [providers, id]);

  return (
    <>
      <form onSubmit={handleSubmit} style={{ width: "100%" }} ref={formRef}>
        <Grid
          gap={3}
          templateColumns={{ base: "1fr", md: "repeat(2,minmax(auto,1fr))" }}
        >
          <GridItem
            p={{ base: 3, md: 6 }}
            background="white"
            rounded={4}
            boxShadow="sm"
          >
            <Grid gap={3}>
              <GridItem>
                <FormLabel fontSize="xs" color="gray.400">
                  Razon Social:
                </FormLabel>
                <Input
                  size="md"
                  rounded={4}
                  type="text"
                  name="name"
                  defaultValue={provider.name}
                />
              </GridItem>
              <GridItem>
                <FormLabel fontSize="xs" color="gray.400">
                  RFC:
                </FormLabel>
                <Input
                  size="md"
                  rounded={4}
                  type="text"
                  name="rfc"
                  defaultValue={provider.rfc}
                />
              </GridItem>
              <GridItem>
                <FormControl isRequired>
                  <FormLabel fontSize="xs" color="gray.400">
                    Nombre Comercial:
                  </FormLabel>
                  <Input
                    size="md"
                    rounded={4}
                    type="text"
                    name="tradename"
                    defaultValue={provider.tradename}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormLabel fontSize="xs" color="gray.400">
                  Teléfono:
                </FormLabel>
                <Input
                  size="md"
                  rounded={4}
                  type="text"
                  name="phone"
                  defaultValue={provider.phone}
                />
              </GridItem>
              <GridItem>
                <FormLabel fontSize="xs" color="gray.400">
                  Dirección:
                </FormLabel>
                <Textarea
                  size="md"
                  borderRadius={4}
                  type="text"
                  name="address"
                  defaultValue={provider.address}
                />
              </GridItem>
            </Grid>
          </GridItem>
          {/* Contact */}
          <GridItem
            background="gray.200"
            p={{ base: 3, md: 6 }}
            rounded={4}
            boxShadow="sm"
          >
            <Grid gap={3}>
              <GridItem>
                <FormLabel fontSize="xs" color="gray.400">
                  Nombre del representante:
                </FormLabel>
                <Input
                  background="white"
                  size="md"
                  rounded={4}
                  type="text"
                  name="user"
                  defaultValue={provider.user}
                />
              </GridItem>
              <GridItem>
                <FormLabel fontSize="xs" color="gray.400">
                  Teléfono del representante:
                </FormLabel>
                <Input
                  background="white"
                  size="md"
                  rounded={4}
                  type="text"
                  name="userPhone"
                  defaultValue={provider.userPhone}
                />
              </GridItem>
              <GridItem>
                <FormLabel fontSize="xs" color="gray.400">
                  Email:
                </FormLabel>
                <Input
                  background="white"
                  size="md"
                  rounded={4}
                  type="text"
                  disabled
                  defaultValue={provider.userEmail}
                />
              </GridItem>
              <GridItem>
                <FormLabel fontSize="xs" color="gray.400">
                  Password:
                </FormLabel>
                <InputGroup size="md">
                  <Input
                    pr="4.5rem"
                    type={show ? "text" : "password"}
                    background="white"
                    defaultValue={provider.userPassword}
                    disabled
                  />
                  <InputRightElement width="4.5rem">
                    <Button
                      rounded={2}
                      h="1.60rem"
                      size="xs"
                      onClick={showPassword}
                    >
                      {show ? "Ocultar" : "Mostrar"}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </GridItem>
              <Box mt={6}>
                {alert.state ? (
                  <Alert status={alert.type} mb={3}>
                    <AlertIcon />
                    <AlertTitle>{alert.title}</AlertTitle>
                    <AlertDescription>{alert.message}</AlertDescription>
                  </Alert>
                ) : null}
                <Flex justifyContent="end">
                  <Button
                    size="sm"
                    colorScheme="blue"
                    variant="ghost"
                    mr={3}
                    rounded={4}
                    onClick={cancelEdit}
                  >
                    Cancelar
                  </Button>
                  <Button
                    size="sm"
                    colorScheme="blue"
                    type="submit"
                    ml={1}
                    rounded={4}
                    leftIcon={<RepeatIcon />}
                    loadingText="Actualizando Perfil"
                    isLoading={loading}
                  >
                    Actualizar Perfil
                  </Button>
                </Flex>
              </Box>
            </Grid>
          </GridItem>
        </Grid>
      </form>
    </>
  );
}

export default EditProvider;
