import { auth } from "../util/firebase";
import { signOut } from "firebase/auth";
import { useHistory } from "react-router-dom";
import { Box, Grid, Heading, Button, Flex, Tag } from "@chakra-ui/react";
import { Icon } from "@chakra-ui/icons";
import { FaSignOutAlt } from "react-icons/fa";

function Nav({ title, state }) {
  const history = useHistory();

  const handleClick = (e) => {
    e.preventDefault();
    signOut(auth);
  };

  function linkProv(e) {
    e.preventDefault();
    history.push("/proveedores");
  }

  function linkHome(e) {
    e.preventDefault();
    history.push("/");
  }

  return (
    <>
      <Box
        background="white"
        height={12}
        position="fixed"
        top={0}
        left={0}
        zIndex={3}
        width="100%"
        boxShadow="lg"
      >
        <Grid
          justifyContent="center"
          templateColumns="minmax(auto,1400px)"
          height={12}
          alignItems="center"
          px={2}
        >
          <Flex justifyContent="space-between" alignItems="center">
            <Flex>
              <Heading fontSize={{ base: "sm", sm: "lg", md: "xl" }}>
                {title}
              </Heading>
              {state !== undefined ? (
                <Tag
                  rounded={3}
                  size="sm"
                  ml={2}
                  colorScheme={state ? "green" : "red"}
                >
                  {state ? "Activo" : "Desactivado"}
                </Tag>
              ) : null}
            </Flex>
            <Flex justifyContent="space-between">
              <Button
                onClick={linkHome}
                colorScheme="blue"
                size="sm"
                variant="link"
                mr={{ base: 3, sm: 6, md: 6 }}
              >
                Inicio
              </Button>
              <Button
                onClick={linkProv}
                colorScheme="blue"
                size="sm"
                rounded={4}
                variant="link"
                mr={6}
              >
                Proveedores
              </Button>
              <Button
                onClick={handleClick}
                colorScheme="blue"
                variant="solid"
                size="sm"
                rounded={4}
                rightIcon={<Icon as={FaSignOutAlt} />}
              >
                Salir
              </Button>
            </Flex>
          </Flex>
        </Grid>
      </Box>
    </>
  );
}

export default Nav;
