import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { db } from "./util/firebase";
import {
  collection,
  query,
  onSnapshot,
  orderBy,
  where,
} from "firebase/firestore";

import Nav from "./particles/Nav";
import InvoiceList from "./particles/InvoiceList";
import Autocomplete from "./particles/Autocomplete";
import { ProvidersContext } from "./context/Providers";
import { providerFields } from "./helpers/data";
import { Box, Flex, Grid, ButtonGroup, Button } from "@chakra-ui/react";
import { Icon } from "@chakra-ui/icons";
import { FaUserAlt } from "react-icons/fa";

function ProviderInvoices({ history }) {
  let { id } = useParams();
  const { providers } = useContext(ProvidersContext);
  const [provider, setProvider] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [backup, setBackup] = useState([]);
  const [pending, setPending] = useState(true);
  const [filtered, setFiltered] = useState(0);
  const [field, setField] = useState(null);
  const [value, setValue] = useState(null);
  const [disabled, setDisabled] = useState(false);

  function handleClick() {
    history.push(`/proveedor/perfil/${id}`);
  }

  useEffect(() => {
    const copy = [...providers];
    const filterData = copy.filter((e) => e.uid === id);
    setProvider(...filterData);
  }, [providers, id]);

  useEffect(() => {
    if (filtered === 0 && !field && !value) {
      let copy = [...backup];
      setInvoices(copy);
      setPending(false);
      setDisabled(false);
    }

    if (filtered && !field && !value) {
      let copy = [...backup];
      let res = copy.filter((e) => e.state === filtered);
      setInvoices(res);
      setPending(false);
      setDisabled(false);
    }

    if (field && value) {
      let copy = [...backup];
      let res = copy.filter((e) => e[field] === value);
      setInvoices(res);
      setDisabled(true);
      setPending(false);
    }
  }, [filtered, backup, field, value, pending]);

  useEffect(() => {
    const queryInvoices = query(
      collection(db, "invoices"),
      where("provider", "==", id),
      orderBy("time", "desc")
    );
    const unsubscribeInvoices = onSnapshot(queryInvoices, (snap) => {
      let docsInvoices = [];
      snap.forEach((doc) => {
        let data = doc.data();
        const unixTime = doc.data().time;
        const date = new Date(unixTime);
        docsInvoices.push({
          ...provider,
          ...data,
          id: doc.id,
          date: date.toLocaleDateString("es-MX"),
        });
      });
      setBackup(docsInvoices);
    });
    return () => {
      unsubscribeInvoices();
    };
  }, [provider, id]);

  if (pending) {
    return <>loading...</>;
  }

  return (
    <>
      <Nav title={`${provider.tradename}`} state={provider.userState} />
      <Box bg="gray.100" minH="100vh" py={20} px={2}>
        <Grid justifyContent="center" templateColumns="minmax(auto,1400px)">
          <Flex
            justifyContent="space-between"
            flexDirection={{ base: "column-reverse", md: "row" }}
          >
            <Autocomplete
              fields={providerFields}
              data={backup}
              setField={setField}
              setValue={setValue}
            />
            <Button
              rounded={4}
              size="sm"
              colorScheme="blue"
              onClick={handleClick}
              mb={{ base: 3, md: 0 }}
              ml={{ base: 0, sm: 3 }}
              leftIcon={<Icon as={FaUserAlt} />}
            >
              Ver Perfil
            </Button>
          </Flex>
          <Flex
            direction="column"
            background="white"
            p={{ base: 3, md: 6 }}
            boxShadow="sm"
            overflowX="auto"
            overflowY="auto"
            rounded={4}
          >
            <ButtonGroup
              variant="outline"
              mt={{ base: 3, md: 0 }}
              size="sm"
              isAttached
              mb={6}
            >
              <Button onClick={() => setFiltered(1)} disabled={disabled}>
                Pendientes
              </Button>
              <Button onClick={() => setFiltered(2)} disabled={disabled}>
                Rechazadas
              </Button>
              <Button onClick={() => setFiltered(3)} disabled={disabled}>
                Aprobadas
              </Button>
              <Button onClick={() => setFiltered(0)} disabled={disabled}>
                Todas
              </Button>
            </ButtonGroup>
            <InvoiceList data={invoices} messages={true} shortTable={true} />
          </Flex>
        </Grid>
      </Box>
    </>
  );
}

export default ProviderInvoices;
