import { useEffect, useState } from "react";

function DocumentsCounter({ a, b, c, d, e, f, value }) {
  const [count, setCount] = useState(null);

  useEffect(() => {
    let count = 0;
    if (a === value) count += 1;
    if (b === value) count += 1;
    if (c === value) count += 1;
    if (d === value) count += 1;
    if (e === value) count += 1;
    if (f === value) count += 1;
    setCount(count);
  }, [a, b, c, d, e, f, value]);

  return <>{count}</>;
}

export default DocumentsCounter;
