import { useState, useEffect } from "react";
import { db } from "../util/firebase";
import { doc, updateDoc } from "firebase/firestore";
import { Button } from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";

function ApproveInvoice({ id, state }) {
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  async function handleClick() {
    setLoading(true);
    const docRef = doc(db, "invoices", id);
    try {
      await updateDoc(docRef, { state: 3, message: null });
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  useEffect(() => {
    if (state === 3) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
    return () => setDisabled(true);
  }, [state]);

  return (
    <>
      <Button
        rounded={2}
        disabled={disabled}
        onClick={handleClick}
        size="xs"
        colorScheme="blue"
        leftIcon={<CheckIcon />}
        loadingText="Actualizando"
        isLoading={loading}
      >
        Aprobar
      </Button>
    </>
  );
}

export default ApproveInvoice;
